import { lazy } from "react";

const RoutePaths = [
  {
    path: "/login",
    component: lazy(() => import("../Pages/VendorLogin/Login")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/",
    component: lazy(() => import("../Pages/Dashboard/Dashboard")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/products",
    component: lazy(() => import("../Pages/Product/Product")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/product-add",
    component: lazy(() => import("../Pages/Product/Add/ProductForm")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/chat-page",
    component: lazy(() => import("../Pages/ChatPage/ChatPage.js")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/chatPage",
    component: lazy(() => import("../Pages/NewChatApp/Chatpage")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  // {
  //   path: "/product",
  //   component: lazy(() => import("../Pages/AddProduct/Product")),
  //   meta: {
  //     authRoute: false,
  //     roles: ["admin", "subadmin"],
  //   },
  // },
  // {
  //   path: "/product-form",
  //   component: lazy(() => import("../Pages/AddProduct/Form")),
  //   meta: {
  //     authRoute: false,
  //     roles: ["admin", "subadmin"],
  //   },
  // },
];

export default RoutePaths;
